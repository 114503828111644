import React from 'react';
import { Link } from 'gatsby'
import styled from 'styled-components'
import Button from './Button'
import RSOIcon from './RSOIcon'
import SIZES from '../utils/sizes'
import COLORS from '../utils/colors'

const CustomSidebar = styled.div`

    width:420px;
    height:100%;
    background-color:${COLORS.thirdary};
    position:fixed;
    bottom:0;
    right:0;
    z-index:5;
    transform: translateX(100%);
    transition:transform 0.4s ease-in-out;
    
    &.show{
        box-shadow: -2px 0px 10px 1px rgba(0,0,0,0.3);
    }
 

    .sidebar-content{
       
        padding:0 24px;
        height:70%;
        width:100%;
        position:absolute;
        top:50%;
        left:50%;
        transform: translateX(-50%) translateY(-50%);

        display:flex;
        flex-direction:column;
        justify-content:space-between;
        

        .sidebar-rso{
          
            width:182px;
         
            margin: 0 auto;
            ul{
                display:flex;
                justify-content: space-between;
                list-style-type:none;
            }
        }
        .sidebar-nav{
         
            height:50%;
            ul{
                height:100%;
                list-style-type:none;
                display:flex;
                flex-direction:column;
                justify-content: space-between;
                align-items:center;

                

                a{
                    display:block;
                    padding:8px 2px;
                    text-decoration:none;
                    font-family: "Gilroy Light", "Catamaran", Arial, Helvetica, sans-serif;
                    font-size:1.6rem;
                    color: ${COLORS.secundary};
                    box-shadow:none;
                    transition: box-shadow 0.3s ease-out;
                    
                }

                a:hover{
                    box-shadow:0px 4px 0 -2px ${COLORS.secundary};
                }
            }
        }

        .sidebar-contact{
            display:flex;
            flex-direction:column;
            align-items:center;
            
            .btn-contact-reverse{
                background-color:${COLORS.primary};
                margin-bottom:0;
                margin-top:0;
            }

            .btn-contact-reverse:hover{
                background-color:${COLORS.primaryHover};
              
            }
        }

    }

    @media (max-width: ${SIZES.thirdBreakPoint}){

        width:100vw;

     
        
    }

    @media (max-width: ${SIZES.fourthBreakPoint}){

        .sidebar-content{
       
            padding:80px 24px 48px;
            height:100%;
            bottom:0;
            text-align:center;
        
        }

     
        
    }

`


const Sidebar = ( {show} ) => {
    return (
        <CustomSidebar id="my-sidebar" className={show}>
            <div className="sidebar-content">
                <div className="sidebar-rso">
                <ul>
                    <li title="Envoyer e-mail"><RSOIcon to="mailto:marylise.barthelemy@gmail.com?subject=Description de ma demande."><i class="fa fa-envelope"></i></RSOIcon></li>
                    <li title="Page Facebook"><RSOIcon to="https://www.facebook.com/B-comxyz-Solutions-graphiques-web-109996337419110"><i class="fa fa-facebook-f"></i></RSOIcon></li>
                    <li title="Profil Linked-in"><RSOIcon to="https://www.linkedin.com/in/marylise-barth%C3%A9l%C3%A9my-800324169/"><i class="fa fa-linkedin"></i></RSOIcon></li>
                </ul>
                </div>
                <div className="sidebar-nav">
                    <ul>
                        <li>
                            <Link to="/">Accueil</Link>
                        </li>
                        <li>
                            <Link to="/a-propos">b-com</Link>
                        </li>
                        <li>
                            <Link to="/portfolio">Portfolio</Link>
                        </li>
                        <li>
                            <Link to="/blog">Blog</Link>
                        </li>
                    </ul>
                </div>
                <div className="sidebar-contact">
                    <Button ajoutClasse="reverse">Contactez-moi</Button>
                </div>
            </div>
        </CustomSidebar>
    );
};

export default Sidebar;