/*secundary: '#EEE', thirdary: '#f07e42'*/

const COLORS = {
    textColor:'#1b292e',
    primary: '#1b292e',
    secundary: '#fefefe',
    thirdary: '#f07e42',
    fourthary:'#f8f9fd',
    primaryHover:'#fc824c',
    secundaryHover:'#f0f2fa',
    thirdaryHover:'#fc8d5b'
  }
  
  export default COLORS;