import React from 'react';
import { Link } from 'gatsby'
import styled from 'styled-components'
import SIZES from '../utils/sizes'
import COLORS from '../utils/colors'

const IndexMainMenu = styled.nav`

    font-size: 1.2rem;
    font-family: "Gilroy ExtraBold", "Catamaran", Arial, Helvetica, sans-serif;

    ul{
    
        display: flex;
        list-style-type: none;
        justify-content: end;

        li{

            a{
                text-decoration: none;
                display: inline-block;
                padding: 8px 24px;
                transition: color .3s;
                text-align:center;
                font-family: "Gilroy Thin", "Catamaran", Arial, Helvetica, sans-serif;
                color: ${COLORS.primary};
                transition: transform .3s ease-out;

                &:hover{
                    
                    transform: translateX(-16px);
                }

                &::after{
                    
                    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f30a";
                    display:block;
              
                    
                }
            }
        }         
    }

    &.whiteNav{

        

        ul li a
        {
        color: ${COLORS.secundary};
        }
    }

    @media (max-width: ${SIZES.firstBreakPoint}){

        ul li a:hover{
            transform: none;      
        }
    }

    @media (max-width: ${SIZES.thirdBreakPoint}){
        display:none;
    }
`

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

const NavBarBis = ( {page} ) => {

    const label = page==="blog" ? "Retour au blog" : page==="portfolio"? "Retour au portfolio" : "Retour à la page d'accueil"
    const pointeur = page==="blog" ? "/blog" : page==="portfolio"? "/portfolio" : "/"
    const white = page==="portfolio" || page==="contact" ? "whiteNav" : ""

    return (
        <IndexMainMenu className={white}>
            <ul>
                <li>
                    <Link to={pointeur}>{label}</Link>
                </li>
            </ul>
        </IndexMainMenu>
    );
};

export default NavBarBis;