import React from 'react';

import styled from 'styled-components'
import COLORS from "../utils/colors"
import SIZES from "../utils/sizes"


const StyledLink = styled.a`
    display: inline-block;
    padding: 10px 40px;

    background-color: ${COLORS.thirdary};
    border-radius: 32px;


    margin:24px 0;    
    text-decoration: none;
    text-transform:uppercase;
    font-family:"Gilroy ExtraBold", Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    line-height:1.6rem;
    color:${COLORS.secundary};
    letter-spacing:1px;
    position: relative;
    transition: all .3s ease;
    
    &:hover{

        background-color:${COLORS.thirdaryHover};
    }

    &.reverse{
        background-color: ${COLORS.secundary};
        color:${COLORS.thirdary};
     

        &:hover{
            
            background-color: ${COLORS.secundaryHover};
        }
    }

    &.access{
        padding: 8px 24px;
        color:${COLORS.primary};
        -webkit-box-shadow:inset 0px 0px 0px 3px ${COLORS.primary};
        -moz-box-shadow:inset 0px 0px 0px 3px ${COLORS.primary};
        box-shadow:inset 0px 0px 0px 3px ${COLORS.primary};
        background-color:transparent;
        font-size: 0.8rem;

        &:hover{
            background-color:#e8f2ff;
        }
        
    }

    &.hero-style{
        -webkit-box-shadow:inset 0px 0px 0px 3px ${COLORS.secundary};
        -moz-box-shadow:inset 0px 0px 0px 3px ${COLORS.secundary};
        box-shadow:inset 0px 0px 0px 3px ${COLORS.secundary};
        background-color:transparent;

        margin:16px 0;

        &:hover{
            background-color: ${COLORS.thirdary};
            
        }
    }

   

    

    @media (max-width: ${SIZES.fourthBreakPoint}){

        width:100%;
        padding: 8px 32px;
        font-size: 1.2rem;
        text-align:center;
    }
`

const Button = ({to="mailto:marylise.barthelemy@gmail.com?subject=Description de ma demande.", children, ajoutClasse=""}) => {

 
    return (
            <StyledLink className={ajoutClasse} href={to}>{children}</StyledLink>

    );
};

export default Button;