import React from 'react';
import { Link } from 'gatsby'

import styled from 'styled-components'
import SIZES from '../utils/sizes'


const MenuFooter = styled.nav`

    width:50%;

    ul{
        list-style-type: none;
        display: flex;
        justify-content: center;
    }

    li>*{
        text-decoration: none;
        display: block;
        padding: 4px 24px;
        text-align: center;
    }

    li>*:hover{
        text-decoration: underline;
       
    }

    .premier{
        margin-bottom: 24px;
        font-weight: 100;
    }

    .second{
      
       li:first-child, li:nth-child(2){
           border-right: 1px solid #FFF;
       }
    }

    @media (max-width: ${SIZES.secondBreakPoint}){

       width:100%;

    }

    @media (max-width: ${SIZES.thirdBreakPoint}){

        .premier{
            flex-direction:column;
        }

        li>*{
            
            padding: 4px 16px;
            text-align: center;
        }
 
     }

`




const FooterNavBar = () => {

    
    return (
        <MenuFooter>
            <ul className="premier">
                <li>
                    <Link to='/'>Accueil</Link>
                </li>
                
                <li>
                    <Link to='/contact'>Contact</Link>
                </li>
                <li>
                    <Link to='/portfolio'>Portfolio</Link>
                </li>
                <li>
                    <Link to='/blog'>Blog</Link>
                </li>
                <li>
                    <Link to='/a-propos'>A propos</Link>
                </li>
            </ul>
            <ul className="second">
                <li>
                    <Link to='/politique-de-confidentialite'>Politique de confidentialité</Link>
                </li>
                <li>
                    <Link to='/mentions-legales'>Mentions légales</Link>
                </li>
                <li>
                    <Link to='/CGV'>CGV</Link>
                </li>
            </ul>
        </MenuFooter>
    );
};

export default FooterNavBar;