
const SIZES = {
    container: '1280px',
    thirdOfContainer: '500px',
    firstBreakPoint: '1280px',
    secondBreakPoint: '960px',
    thirdBreakPoint: '768px',
    fourthBreakPoint:'480px'
}
export default SIZES;

