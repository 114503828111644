import React, { Component } from 'react';
import Sidebar from './Sidebar';
import styled from 'styled-components'
import COLORS from '../utils/colors'

const StylegToggle = styled.div`
.burger{

    width:40px;
    height:36px;    
    cursor:pointer;   
    position:relative;
    align-self:center;
    z-index:6;

    #icon, #icon::before, #icon::after{
        
        display:block;
        
        width:100%;
        height:4px;
        background-color: ${COLORS.primary};
        border-radius:4px;
        position:absolute;
        top:50%;
    
        transform: translateY(-50%);
        transition: all 0.3s ease-out;
        transition-property: background-color, transform;
    }
    #icon::before{
        content:'';
        /*transform:translateY(-14px);*/
        top:-10px;
        
    }
    #icon::after{
        content:'';
        /*transform:translateY(10px);*/
        top:14px;
    }
}
.cross #icon{
    background-color: rgba(0,0,0,0);
}
.cross #icon::before{
    transform:rotate(45deg);
    top:0;
  
    
}
.cross #icon::after{
    transform:rotate(-45deg);
    top:0;
  
    
}
.show{
    transform: translateX(0);
}

&.whiteToggle{

    .burger 
    {
        #icon, #icon::before, #icon::after
        {
            background-color: ${COLORS.secundary};
            transition: all 0.3s ease-out;
            transition-property: background-color, transform;
        }
    }

        .cross #icon{
            background-color: rgba(0,0,0,0);
        }
        
    }
`


class Toggle extends Component {

    state = {
        isMenuOn: false,
    }

    render() {
        const isShown = this.state.isMenuOn;
        let rect;
        const white = this.props.page==="portfolio" ? "whiteToggle" : ""
       
        return (
            <StylegToggle className={white}>
                <div className={ isShown? "burger cross" : "burger"} onClick={ () => { 
                    this.setState( {isMenuOn: !isShown} );  
                    let burger = document.querySelector(".burger");
                    if(!isShown){
                        
                            rect = burger.getBoundingClientRect()
                            console.log(rect.x);
                            burger.style.position = "fixed"
                            burger.style.left = rect.left +"px"
                            burger.style.top = rect.top +"px"    
                    }
                    else{
                        burger.style.position = "relative"
                        burger.style.left = 0
                        burger.style.top = 0
                    }
                    }}>
                <span id="icon"></span>
                </div>
                <Sidebar show={ isShown? "show" : ""}/>
            </StylegToggle>
        );
    }
}

export default Toggle;