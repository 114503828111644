import React from 'react';
import styled from 'styled-components';
import RSOIcon from './RSOIcon';
import SIZES from '../utils/sizes'

const StyledBlockRSO = styled.div`

   
    &.mini{
        position:absolute;
        right:72px;
        margin:0 24px;
        width: 104px;
    }
  
    width: 100%;
    margin:72px auto;
    display:flex;
    justify-content:center;
    
    ul{
        list-style-type: none;
        display: flex;
        width:182px;
        justify-content: space-between;
       
                
        li{
            line-height: 2.2rem;
        }

       /* li:not(.mini):nth-child(2)
        {
            border-radius:50%;
            box-shadow: 0 0 0 0 rgba(255, 109, 44, 1);
            transform: scale(1);
            animation: pulse 1.2s infinite;
        }*/

        @keyframes pulse {
            0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(255, 109, 44, 0.7);
            }
        
            70% {
                transform: scale(1);
                box-shadow: 0 0 0 10px rgba(255, 109, 44, 0);
            }
        
            100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(255, 109, 44, 0);
            }
        }
    }

@media (max-width: ${SIZES.secondBreakPoint}){

    &.mini{
        right:48px;
    }
}

@media (max-width: ${SIZES.fourthBreakPoint}){
    
    &.mini{
        right:40px;
    }
}

`

const BlockRSO = ({mini=""}) => {
    return (
        <StyledBlockRSO className={mini}>
            <ul>
                <li className={mini} title="Envoyer e-mail"><RSOIcon mini={mini} to="mailto:marylise.barthelemy@gmail.com?subject=Description de ma demande."><i class="fa fa-envelope"></i></RSOIcon></li>
                <li className={mini} title="Page Facebook"><RSOIcon mini={mini} to="https://www.facebook.com/B-comxyz-Solutions-graphiques-web-109996337419110"><i class="fa fa-facebook-f"></i></RSOIcon></li>
                <li className={mini} title="Profil Linked-in"><RSOIcon mini={mini} to="https://www.linkedin.com/in/marylise-barth%C3%A9l%C3%A9my-800324169/"><i class="fa fa-linkedin"></i></RSOIcon></li>
             </ul>
        </StyledBlockRSO>
    );
};

export default BlockRSO;